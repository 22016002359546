import gql from "graphql-tag";

export const RoleDefinitionFragment = gql`
  fragment RoleDefinitionFragment on RoleDefinition {
    id
    sectionEnabled
    contactLink
    recoverPasswordLink
    registerLink
    logo
    logoLink
    counterPetsales
  }
`;

export const UserRoleFragment = gql`
  fragment UserRoleFragment on Role {
    isCurrentRole
    token
    name
    isAuthorizedToCreateLitter
    isLoginAs
    roleDefinition {
      ...RoleDefinitionFragment
    }
    partnerRelation {
      extId
      partner {
        extId
        name
      }
    }
  }
  ${RoleDefinitionFragment}
`;

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    label
    subLabel
    reference
    images {
      src

      listing: cloudinaryImage (rawTransformation: "t_fid_listing") {
        src
        webp: src (format: "webp")
      }
      detail: cloudinaryImage (rawTransformation: "t_fid_detail") {
        src
        webp: src (format: "webp")
      }
      thumb: cloudinaryImage (rawTransformation: "t_fid_thumb") {
        src
        webp: src (format: "webp")
      }
    }
    wholesalers {
      label
      isClientReferenceRequired
      clientReferenceDescription
    }
    categories {
      label
    }
    infoUrl
    shortDescription
    longDescription
    price
    promo
    promoPrice
    promoStartDate
    promoEndDate
    newProduct
    newProductStartDate
    newProductEndDate
    productType
  }
`;
