import { defaultLocale } from "@royalcanin-fr-loyalty/settings";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { RoleDefinitionFragment } from "./Fragments";
import {
  UserRoleDefinitionByReferer,
  UserRoleDefinitionByRefererVariables,
} from "./__generated__/UserRoleDefinitionByReferer";

const UserRoleDefinitionByRefererQuery = gql`
  query UserRoleDefinitionByReferer($referer: String!, $locale: String!) {
    userRoleDefinitionByReferer(referer: $referer, locale: $locale) {
      ...RoleDefinitionFragment
    }
  }
  ${RoleDefinitionFragment}
`;

export const useUserRoleDefinitionByReferer = () => {
  const res = useQuery<
    UserRoleDefinitionByReferer,
    UserRoleDefinitionByRefererVariables
  >(UserRoleDefinitionByRefererQuery, {
    ssr: false,
    variables: {
      referer: typeof window !== "undefined" ? window.location.href : "",
      locale: defaultLocale,
    },
  });

  const roleDefinition = res.data && res.data.userRoleDefinitionByReferer;

  return {
    ...res,
    data: roleDefinition,
  };
};
