import { defaultLocale } from "@royalcanin-fr-loyalty/settings";
import { LayoutLogin as DefaultLayout } from "@royalcanin-fr-loyalty/ui-kit";
import React from "react";
import footerLinks from "../../../datastore/data/settingsFooterLinks.json";

export const LayoutLogin = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <DefaultLayout
        layoutFooterProps={{
          locale: defaultLocale,
          footerLinks: footerLinks.map(footerLink => ({
            ...footerLink,
            target: footerLink.target as "_blank",
          })),
        }}
      >
        {children}
      </DefaultLayout>
    </>
  );
};
