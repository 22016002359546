import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useApolloClient, useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { UserRoleFragment } from "./Fragments";
import { UserRole, UserRoleVariables } from "./__generated__/UserRole";

const UserRoleQuery = gql`
  query UserRole($token: String!, $locale: Locale!) {
    userRole(token: $token, locale: $locale) {
      result {
        ...UserRoleFragment
      }
      error {
        message
        code
      }
    }
  }
  ${UserRoleFragment}
`;

export const useFetchUserRole = () => {
  const client = useApolloClient();
  return (variables: Omit<UserRoleVariables, "locale">) =>
    client.query<UserRole, UserRoleVariables>({
      query: UserRoleQuery,
      variables: {
        locale: Locale.fr,
        ...variables,
      },
    });
};

export const useUserRole = () => {
  const token = useUserToken();
  const { loading: initialLoading, networkStatus, ...res } = useQuery<
    UserRole,
    UserRoleVariables
  >(UserRoleQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale.fr,
    },
  });

  const loading = initialLoading || (!initialLoading && !networkStatus);

  const role = res.data && res.data.userRole && res.data.userRole.result;

  return {
    ...res,
    networkStatus,
    loading,
    data: role,
  };
};
