import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useApolloClient, useQuery } from "react-apollo";
import { Locale } from "../../../../../__generated__/globalTypes";
import { UserRoleFragment } from "./Fragments";
import { useUserRoleDefinitionByReferer } from "./useUserRoleDefinitionByReferer";
import { UserRoles, UserRolesVariables } from "./__generated__/UserRoles";

const UserRolesQuery = gql`
  query UserRoles(
    $token: String!
    $locale: Locale!
    $contextRoleDefinitionId: String
  ) {
    userRoles(
      token: $token
      locale: $locale
      contextRoleDefinitionId: $contextRoleDefinitionId
    ) {
      result {
        ...UserRoleFragment
      }
      error {
        message
        code
      }
    }
  }
  ${UserRoleFragment}
`;

export const useFetchUserRoles = () => {
  const { data: contextRoleDefinition } = useUserRoleDefinitionByReferer();
  const client = useApolloClient();
  return (
    variables: Omit<UserRolesVariables, "contextRoleDefinitionId" | "locale">,
  ) =>
    client.query<UserRoles, UserRolesVariables>({
      query: UserRolesQuery,
      variables: {
        contextRoleDefinitionId: contextRoleDefinition
          ? contextRoleDefinition.id
          : undefined,
        locale: Locale.fr,
        ...variables,
      },
    });
};

export const useUserRoles = () => {
  const token = useUserToken();
  const { data: contextRoleDefinition } = useUserRoleDefinitionByReferer();
  const res = useQuery<UserRoles, UserRolesVariables>(UserRolesQuery, {
    skip: !token,
    variables: {
      token,
      locale: Locale.fr,
      contextRoleDefinitionId: contextRoleDefinition
        ? contextRoleDefinition.id
        : undefined,
    },
  });

  const roles = res.data && res.data.userRoles && res.data.userRoles.result;

  return {
    ...res,
    data: roles,
  };
};
