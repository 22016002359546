import { useUserContext } from "@igloo-be-omnipartners/hooks";
import { Redirect, WindowLocation } from "@reach/router";
import { Login, PageLoading } from "@royalcanin-fr-loyalty/ui-kit";
import qs from "querystring";
import React, { useEffect } from "react";
import { LayoutLogin } from "../components/LayoutLogin";
import { LoginForm } from "../components/login/LoginForm";
import { useIsAccessTokenLoginLoading } from "../lib/AccessTokenLogin";
import { useUserRoleDefinitionByReferer } from "../lib/hooks/useUserRoleDefinitionByReferer";
import { useLocationContext } from "../lib/LocationProvider";
import { useHasMounted } from "../lib/utils";
import { withPage } from "../lib/withPage";

const Index = ({
  location,
}: {
  location: WindowLocation<{
    loginError: string;
    loginBackUrl: string;
    extId?: string;
  }>;
}) => {
  const { data: roleDefinition } = useUserRoleDefinitionByReferer();
  const loacationStateReady = !!(useHasMounted() && location.state);
  const { loginError, loginBackUrl, extId } =
    (loacationStateReady && location.state) || {};
  const { token, ready } = useUserContext();
  const loading = useIsAccessTokenLoginLoading();
  const { query } = useLocationContext();

  const externalLoginUrl = "/api/okta/login";
  const externalLogoutUrl = "/api/okta/logout";

  useEffect(() => {
    if (loacationStateReady && !token && !loading && ready && !loginError) {
      window.location.href = externalLoginUrl;
    }
  }, [loginError, loading, ready, loacationStateReady]);

  return (
    <LayoutLogin>
      {token && ready && (
        <Redirect noThrow to={`/points?${qs.stringify(query)}`} />
      )}

      {(!!loading || !ready) && <PageLoading />}

      <Login
        loginHeaderProps={{
          logo:
            roleDefinition && roleDefinition.logo ? roleDefinition.logo : "",
          logoLink: roleDefinition?.logoLink || undefined,
          logoMainHref: "/",
          newUserLink:
            roleDefinition && roleDefinition.registerLink
              ? roleDefinition.registerLink
              : "",
        }}
      >
        <LoginForm
          roleDefinition={roleDefinition}
          error={loginError}
          loading={!ready || loading}
          backUrl={loginBackUrl}
          partnerExtId={extId}
          externalLoginUrl={externalLogoutUrl}
        />
      </Login>
    </LayoutLogin>
  );
};

export default withPage(Index);
